import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCampaignsScenariosStore = defineStore ({
  id: 'campaignsScenarios',
  state: () => ({
    campaigns: [],
    campaignId: '',
    stepId: '',
    campaignName: '',
    campaignTemplateId: '',
    campaignCompanyId: '',
    campaignsActiveCount: 0,
    campaignsFinishedCount: 0,
    campaignTemplateState: '',
    campaignState: '',
    campaignStatistics: [],
    campaignErrors: [],
    campaignErrorCount: 0,
    campaignUpdateAt: '',
    addressees: [],
    scenarioEmptyTemplate: [{id: 1, name: 'Пустой сценарий', description: 'Начните создание сценария с чистого листа'}],
    scenarioTemplates: [],
    scenarioTemplateId: '',
    scenarioTemplateName: '',
    scenarioTemplateCompanyId: '',
    scenarioTemplateFromId: '',
    scenarioTemplateConfig: {},
    smsAdressees: [],
    emailAdressees: [],
    campaignsCount: '',
    scenarioTemplatesCount: '',
    campaignCurrentPage: 1,
    campaignPerPage: 10,
    campaignLastPage: 1,
    senderName: '',
    senderText: '',
    smsAddresseesCount: '',
    smsSending: '',
    smsDelivered: '',
    smsErrors: '',
    senderAddress: '',
    emailSenderName: '',
    themeMassage: '',
    emailPreheader: '',
    tempalteName: '',
    emailCreatedAt: '',
    emailAdresses: '',
    emailSending: '',
    emailDelivered: '',
    emailOpened: '',
    emailClicked: '',
    emailErrors: '',
    templateHtmlCode: '',
    emailTemplateId: '',
    pushAddresseesCount: '',
    pushSending: '',
    pushDelivered: '',
    pushErrors: '',
    pushAdressees: [],
    campaignName: '',
    campaignState: '',
    campaignStartAt: '',
    campaignSegmentId: '',
    campaignSegmentName: '',
    campaignSenderName: '',
    campaignViberIDId: '',
    campaignViberIDName: '',
    campaignViberBody: '',
    campaignViberImg: '',
    campaignBtnText: '',
    campaignBtnLink: '',
    campaignProgress: '',
    campaignAllRecipients: 0,
    campaignSent: 0,
    campaignDelivered: 0,
    campaignErrors: 0,
    campaignOpened: 0,
    campaignClicked: 0,
    errors: [],
    templateId: '',
    isLoading: false,
  }),
  getters: {
    scenarioCampaignData: (state) => {
      let scenarioCampaignData = {}
      
      if (state.campaignName) {
        scenarioCampaignData['name'] = state.campaignName
      }
      if (state.campaignCompanyId) {
        scenarioCampaignData['company_id'] = parseInt(state.campaignCompanyId)
      }
      if (state.campaignTemplateId) {
        scenarioCampaignData['scenario_template_id'] = parseInt(state.campaignTemplateId)
      }
      
      return scenarioCampaignData
    },
    scenarioTemplateData: (state) => {
      let scenarioTemplateData = {}

      if (this.scenarioTemplateName) {
        scenarioTemplateData['name'] = this.scenarioTemplateName
      }
      if (this.scenarioTemplateCompanyId) {
        scenarioTemplateData['company_id'] = parseInt(this.scenarioTemplateCompanyId)
      }
      if (this.scenarioTemplateFromId) {
        scenarioTemplateData['template_id'] = parseInt(this.scenarioTemplateFromId)
      }
      if (this.scenarioTemplateConfig) {
        scenarioTemplateData['start_step'] = parseInt(this.scenarioTemplateConfig)
      }

      return scenarioTemplateData
    },
    preparedCampaigns: (state) => state.campaigns.map(campaign => {
      return {
        id: campaign.id.toString(),
        name: campaign.name,
        startAt: campaign.send_at !== null ? campaign.send_at : '',
        createdAt: campaign.created_at !== null ? campaign.created_at : '',
        state: campaign.state,
        segmentName: campaign?.recipients_sourceable?.name || campaign?.segment_name || '-',
        ownerName: campaign?.author_name || '',
        recipientsCount: campaign?.stats_addressee_count || 0,
        sent: campaign?.stats_sent_count || 0,
        steps: campaign?.stats_steps_count || 0,
        delivered: campaign?.stats_delivered_count || 0,
        errors: campaign?.stats_errors_count || 0,
        progress: campaign.progress || 0
      }
    }),
    preparedScenariosTemplates: (state) => state.scenarioTemplates.map(scenariosTemplate => {
      return {
        id: scenariosTemplate.id.toString(),
        name: scenariosTemplate.name,
        companyId: scenariosTemplate.company_id,
        steps: scenariosTemplate.steps_count,
        emailChannel: scenariosTemplate.email_count,
        smsChannel: scenariosTemplate.sms_count,
        pushChannel: scenariosTemplate.mobile_push_count,
        body: scenariosTemplate.description,
        type: scenariosTemplate.type
      }
    }),
    preparedScenariosTemplatesForSelect: (state) => state.scenarioTemplates.map(scenariosTemplate => {
      return {
        value: scenariosTemplate.id.toString(),
        text: scenariosTemplate.name,
      }
    }),
    preparedCampaignStatistics: (state) => state.campaignStatistics.map(campaignStatistic => {
      return {
        id: campaignStatistic.id.toString(),
        step: campaignStatistic.step_number,
        delivered: campaignStatistic?.delivered_count || 0,
        errors: campaignStatistic?.errors_count || 0,
        recipientsCount: campaignStatistic?.addressee_count || 0,
        sent: campaignStatistic?.sent_count || 0,
        type: campaignStatistic.kind,
        updatedAt: campaignStatistic.updated_at !== null ? campaignStatistic.updated_at : '',
      }
    }),
    preparedCampaignDetailsStats: (state) => state.stats.map(stats => {
      return {
        id: stats.id.toString(),
        addresseesCount: stats.addressee_count,
        sending: stats.sent_count,
        delivered: stats.delivered_count,
        errors: stats.errors_count
      }
    }),
    preparedCampaignErrors: (state) => state.campaignErrors.map(campaignErrors => {
      return {
        id: campaignErrors.id.toString(),
        errors: campaignErrors?.stats_errors_count || 0,
        updatedAt: campaignErrors.updated_at !== null ? campaignErrors.updated_at : '',
      }
    }),
    preparedAddressees: (state) => state.addressees.map(addressee => {
      return {
        id: addressee.id.toString(),
        phone: addressee.phone,
        state: addressee.state,
        openedAt: addressee.updated_at || '',
        email: addressee.email,
        step: addressee.step,
        type: addressee.type,
        date: addressee.date || ''
      }
    }),
    preparedEmailAddressees: (state) => state.emailAdressees.map(address => {
      return {
        id: address.id.toString(),
        email: address.email,
        state: address.state,
        createdAt: address.updated_at,
      }
    }),
    preparedPushAddressees: (state) => state.pushAdressees.map(pushAddresseesCount => {
      return {
        id: pushAddresseesCount.id.toString(),
        phone: pushAddresseesCount.phone,
        state: pushAddresseesCount.state,
        openedAt: pushAddresseesCount.read_at || ''
      }
    }),
    preparedErrors: (state) => state.campaignErrors.map(errors => {
      return {
        id: errors.id.toString(),
        step: errors.step_number,
        kind: errors.kind,
        type: errors.type,
        date: errors.updated_at || ''
      }
    }),
  },
  actions: {
    setCampaignsScenariosDefault() {
      this.campaignId = ''
      this.campaignName = ''
      this.campaignTemplateId = ''
      this.campaignCompanyId = ''
    },
    setCampaignPaginationDefault() {
      this.campaignCurrentPage = 1
      this.campaignPerPage = 10
      this.campaignLastPage = 1
    },
    setScenariosTemplateDefault() {
      this.scenarioTemplateName = ''
      this.scenarioTemplateCompanyId = ''
      this.scenarioTemplateFromId = ''
      this.this.scenarioTemplateConfig = {}
    },
    async getCampaignScenariosAllStatistics() {
      this.isLoading = true
      axios
        .get('api/v1/scenarios/all_stats')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignsActiveCount = response.data.active_count || 0
            this.campaignsFinishedCount = response.data.finished_count || 0
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignScenarios(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage

      this.isLoading = true
      axios
        .get('api/v1/scenarios', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaigns = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.campaignsCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignStatistics() {
      this.isLoading = true
      axios
        .get(`api/v1/scenarios/${this.campaignId}/step_runs`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignStatistics = response.data.data
            this.stepNumber = response.data.data.step_number
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignErrors(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      
      this.isLoading = true
      axios
        .get(`api/v1/scenarios/${this.campaignId}/errors`, { params: { page: this.campaignCurrentPage, per: perPage}})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignErrors = response.data.data
            this.campaignUpdateAt = response.data.data[0]?.updated_at || ''
            this.campaignErrorCount = response.data.total
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCampaignScenario(callback = {}) {
      this.isLoading = true
      axios
        .post('/api/v1/scenarios', this.scenarioCampaignData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            if (typeof callback == 'function') {
              callback('success', response.data.scenario)
            } else {
              router.push({name: 'newsletter_scenarios'})
              toast.success('Сценарий успешно создан')
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
          if (typeof callback == 'function') {
            callback('failed', {})
            }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startCampaignScenario() {
      this.isLoading = true
      axios
        .post(`/api/v1/scenarios/${this.campaignId}/start`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Сценарий успешно запущен')
            this.getCampaignScenario()
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(textError)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async pauseCampaignScenario() {
      this.isLoading = true
      axios
        .post(`/api/v1/scenarios/${this.campaignId}/pause`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Сценарий успешно остановлен')
            this.getCampaignScenario()
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(textError)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async resumeCampaignScenario() {
      this.isLoading = true
      axios
        .post(`api/v1/scenarios/${this.campaignId}/resume`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Сценарий успешно запущен')
            this.getCampaignScenario()
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(textError)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async finalCampaignScenario() {
      this.isLoading = true
      axios
        .post(`api/v1/scenarios/${this.campaignId}/finish`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success('Сценарий успешно завершен')
            this.getCampaignScenario()
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(textError)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCampaignScenario(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/scenarios/${this.campaignId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.campaignName = response.data.name
            this.campaignState = response.data.state
            this.campaignTemplateState = response.data.configuration_state
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCampaignScenario(config, deletedStepIds) {
      let currentScenarioData = {
        id: parseInt(this.campaignId),
        name: this.campaignName,
        start_step: config,
        marked_to_delete: deletedStepIds
      }

      this.isLoading = true
      axios
        .post(`api/v1/scenarios/${this.campaignId}`, currentScenarioData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201) && response.data.scenario) {
            this.campaignName = response.data.scenario.name
            this.campaignState = response.data.scenario.state
            router.push(`/newsletter/scenarios/${this.campaignId}`)
            toast.success('Сценарий успешно обновлён')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteCampaignScenario(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let campaignId = ''
        
        if (typeof payload == 'object') {
          campaignId = parseInt(this.campaignId)
        } else {
          campaignId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/scenarios/${campaignId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              router.push({name: 'newsletter_scenarios'})
              toast.success('Сценарий успешно удалён')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getScenarioTemplates(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      
      this.isLoading = true
      axios
        .get('api/v1/scenario_templates', { params: { page: this.campaignCurrentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.scenarioTemplates = [...this.scenarioEmptyTemplate, ...response.data.data.filter(element => element.id !== 1)]
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.scenarioTemplatesCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCompanyScenarioTemplates(companyId, currentPerPage) {
      let perPage = currentPerPage || 100
      let params = { params: { page: 1, per: perPage }}

      if (companyId !== '') {
        params.params['company_id'] = parseInt(companyId)
      }

      this.isLoading = true
      axios
        .get('api/v1/scenario_templates', params)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.scenarioTemplates = [...this.scenarioEmptyTemplate, ...response.data.data.filter(element => element.id !== 1)]
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
            this.scenarioTemplatesCount = response.data.total
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getScenarioTemplate(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/scenario_templates/${this.scenarioTemplateId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.scenarioTemplateName = response.data.name
            this.scenarioTemplateCompanyId = response.data.companyId
            this.scenarioTemplateConfig = response.data.start_step
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async saveTemplate(config, deletedStepIds, currentCompanyId) {
      let currentScenarioData = {
        id: parseInt(this.campaignId),
        company_id: currentCompanyId,
        name: this.campaignName,
        start_step: config,
        marked_to_delete: deletedStepIds
      }

      this.isLoading = true
      axios
        .post('api/v1/scenario_templates', currentScenarioData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            router.push('/newsletter/scenarios/templates')
            toast.success('Шаблон успешно сохранен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createScenarioTemplate() {
      this.isLoading = true
      axios
        .post('api/v1/scenario_templates', this.scenarioTemplateData)
        .then((response) => {
          if (response.status === 201 && response.data) {
            //router.push({name: 'newsletter_scenarios'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateScenarioTemplate(config, deletedStepIds, currentCompanyId) {
      let currentScenarioData = {
        id: parseInt(this.campaignId),
        company_id: currentCompanyId,
        name: this.campaignName,
        start_step: config,
        marked_to_delete: deletedStepIds
      }

      this.isLoading = true
      axios
        .post(`api/v1/scenario_templates/${this.scenarioTemplateId}`, currentScenarioData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            router.push('/newsletter/scenarios/templates')
            toast.success('Шаблон успешно сохранен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteScenarioTemplate(payload, callback = {}) {
      if (confirm("Вы действительно хотите удалить?")) {
        let templateId = ''

        if (typeof payload == 'object') {
          templateId = parseInt(this.scenarioTemplateId)
        } else {
          templateId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/scenario_templates/${templateId}`)
          .then((response) => {
            if (response.status === 200 && response.data) {
              toast.success('Шаблон успешно удалён')
              this.getScenarioTemplates()
              if (typeof callback == 'function') {
                callback('success', response.data.scenario)
              }
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
              if (typeof callback == 'function') {
                callback('failed', {})
              }
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async duplicateScenarioTemplate() {
      this.isLoading = true
      axios
        .post(`api/v1/scenario_templates/${this.scenarioTemplateId}/duplicate`)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            this.getScenarioTemplates()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getScenarioDetails(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/scenarios/${this.campaignId}/step_runs/${this.stepId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.stats = response.data.stats
            this.stepInfo = response.data.step_configuration
            if (this.stepInfo.sms_sender_name_id) {
              this.senderName = response.data.step_configuration.sms_sender_name_name
              this.senderText = response.data.step_configuration.message
              this.smsAddresseesCount = response.data.stats.addressee_count
              this.smsSending = response.data.stats.sent_count
              this.smsDelivered = response.data.stats.delivered_count
              this.smsErrors = response.data.stats.errors_count
            }
            if (this.stepInfo.email_sender_name_id) {
              this.senderAddress = response.data.step_configuration.email_sender_name_address
              this.emailSenderName = response.data.step_configuration.email_sender_name_name
              this.emailPreheader = response.data.step_configuration.preheader
              this.themeMassage = response.data.step_configuration.subject
              this.templateHtmlCode = response.data.step_configuration.email_template_body
              this.emailTemplateId = response.data.step_configuration.email_template_id
              this.emailCreatedAt = response.data.step_configuration.created_at
              this.emailAdresses = response.data.stats.addressee_count
              this.emailSending = response.data.stats.sent_count
              this.emailDelivered = response.data.stats.delivered_count                
              this.emailOpened = response.data.stats.opened_count
              this.emailClicked = response.data.stats.clicked_count
              this.emailErrors = response.data.stats.errors_count
            }
            if (this.stepInfo.mobile_push_app_id) {
              this.senderName = response.data.step_configuration.mobile_push_app_name
              this.senderText = response.data.step_configuration.message
              this.pushAddresseesCount = response.data.stats.addressee_count
              this.pushSending = response.data.stats.sent_count
              this.pushDelivered = response.data.stats.delivered_count
              this.pushErrors = response.data.stats.errors_count
            }
            if (this.stepInfo.viber_id_id) {
              this.campaignViberImg = response.data?.step_configuration.image.original?.url || '' 
              this.campaignStartAt = response.data.step_configuration.sent_at || ''
              this.campaignViberBody = response.data.step_configuration.message || ''
              this.campaignProgress = response.data.step_configuration.progress || 0
              this.campaignViberIDId = response.data?.step_configuration.viber_id_id  
              this.campaignBtnText = response.data.step_configuration.btn_name
              this.campaignBtnLink = response.data.step_configuration.btn_url
              this.campaignViberIDName = response.data?.step_configuration.viber_id_name || ''
              this.campaignAllRecipients = response.data.stats.addressee_count || 0
              this.campaignSent = response.data.stats.sent_count || 0
              this.campaignDelivered = response.data.stats.delivered_count || 0
              this.campaignErrors = response.data.stats.errors_count || 0
            }
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getScenarioAddressees(currentPerPage) {
      let perPage = currentPerPage || this.campaignPerPage
      
      this.isLoading = true
      axios
        .get(`api/v1/scenarios/${this.campaignId}/step_runs/${this.stepId}/addressees`, { params: { page: this.campaignCurrentPage, per: perPage}})
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.addressees = response.data.data
            this.campaignCurrentPage = response.data.current_page
            this.campaignLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    }
  }
})